import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses, useClsUtils } from 'styles';
import { Helmet } from 'react-helmet-async';
import Card from 'react-app/components/Card';
import CardHeader from 'react-app/components/CardHeader';
import CardTitle from 'react-app/components/CardTitle';
import CardMedia from 'react-app/components/CardMedia';
import CardMediaCarousel from 'react-app/components/CardMediaCarousel';
import CardContent from 'react-app/components/CardContent';
import CardActions from 'react-app/components/CardActions';
import CardActionArea from 'components/CardActionArea';
import Typography from '@mui/material/Typography';
import { useControlled } from '@mui/material/utils';
import Img from 'react-app/components/Img';
import Dialog from 'react-app/components/Dialog';
import { formatNumber, isImage } from 'lib/helpers';
import { useMemoCallback } from 'react-app/hooks';
import Label from 'react-app/components/Label';
import { sale as sizes } from 'config/sizes.cjs';

export const cardSizes = {
	small: {
		xs: 300,
		phone: 340,
		sm: 360
	},
	medium: {
		xs: 300,
		phone: 340,
		sm: 340,
		md: 360
	},
	large: {
		xs: 320,
		phone: 340,
		sm: 360,
		md: 420
	}
};

export const mediaSizes = {
	small: {
		xs: 120,
		sm: 130,
		md: 140
	},
	medium: cardSizes.medium,
	large: cardSizes.large
};

const HeadingProps = {
	lineClamp: 2
};

const SubheadingProps = {
	lineClamp: 2
};

const defaultProps = {
	size: 'medium'
};

const useClasses = createClasses((theme, {size = defaultProps.size}) => ({
	root: {
		margin: 0,
		...(size === 'small' && {
			maxWidth: cardSizes.small.xs,
			[theme.breakpoints.up('phone')]: {
				maxWidth: cardSizes.small.phone
			},
			[theme.breakpoints.up('sm')]: {
				maxWidth: cardSizes.small.sm
			},
			[theme.breakpoints.up('md')]: {
				maxWidth: cardSizes.small.md
			}
		}),
		...(size === 'medium' && {
			maxWidth: cardSizes.medium.xs,
			[theme.breakpoints.up('phone')]: {
				maxWidth: cardSizes.medium.phone
			},
			[theme.breakpoints.up('sm')]: {
				maxWidth: cardSizes.medium.sm
			},
			[theme.breakpoints.up('md')]: {
				maxWidth: cardSizes.medium.md
			}
		}),
		...(size === 'large' && {
			maxWidth: cardSizes.large.xs,
			[theme.breakpoints.up('phone')]: {
				maxWidth: cardSizes.large.phone
			},
			[theme.breakpoints.up('sm')]: {
				maxWidth: cardSizes.large.sm
			},
			[theme.breakpoints.up('md')]: {
				maxWidth: cardSizes.large.md
			}
		})
	},
	small: {
		border: 'none'
	},
	medium: {},
	large: {},
	cardHeader: {
		width: '100%',
		alignItems: 'flex-start',
		flexGrow: 1,
		...(size === 'small' && {
			display: 'flex',
			maxWidth: '100%',
			flexDirection: 'column',
			justifyContent: 'space-between',
			overflow: 'hidden',
			maxHeight: 'none',
			alignItems: 'flex-start'
		})
	},
	cardMedia: {
		width: '100%',
		// borderBottom: `solid 1px ${theme.vars.palette.divider}`,
		...(size === 'small' && {
			borderBottom: 'none',
			position: 'relative',
			overflow: 'hidden',
			maxWidth: mediaSizes.small.xs,
			maxHeight: mediaSizes.small.xs,
			[theme.breakpoints.up('sm')]: {
				maxWidth: mediaSizes.small.sm,
				maxHeight: mediaSizes.small.sm
			},
			[theme.breakpoints.up('md')]: {
				maxWidth: mediaSizes.small.md,
				maxHeight: mediaSizes.small.md
			}
		})
	},
	cardTitle: {
		justifyContent: 'flex-start',
		minHeight: theme.spacing(5)
	},
	cardContent: {
		paddingTop: 0,
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column',
		'&&': {
			paddingTop: 0
		}
	},
	cardActionArea: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		justifyContent: 'flex-start',
		borderRadius: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
		...(size === 'small' && {
			flexDirection: 'row',
			alignItems: 'stretch',
			justifyContent: 'space-between'
		})
	},
	heading: {
		lineHeight: 1.3,
		fontSize: theme.typography.pxToRem(15),
		[theme.breakpoints.down('tablet')]: {
			...theme.mixins.lineClamp,
			fontSize: theme.typography.pxToRem(14)
		},
		...(size === 'small' && {
			fontSize: theme.typography.pxToRem(14),
			whiteSpace: 'initial'
		})
	},
	subheading: {
		marginTop: theme.spacing(.25),
		lineHeight: 1.2,
		fontSize: theme.typography.pxToRem(13),
		color: theme.vars.palette.text.secondary,
		[theme.breakpoints.down('tablet')]: {
			...theme.mixins.textOverflow,
			fontSize: theme.typography.pxToRem(12)
		},
		...(size === 'small' && {
			fontSize: theme.typography.pxToRem(12)
		})
	},
	image: {
		minWidth: '100%',
		minHeight: '100%',
		maxWidth: '100%',
		maxHeight: '100%',
		backgroundColor: theme.vars.palette.background.placeholder,
		'& > img': {
			width: '100%',
			objectFit: 'cover',
			objectPosition: 'center'
		}
	},
	content: {
		flexGrow: 1,
		fontSize: theme.typography.pxToRem(14),
		'& ul': {
			margin: 0,
			paddingLeft: theme.spacing(2),
			'& > li': {
				marginBottom: theme.spacing(.5)
			}
		},
		'& > p': {
			marginBottom: theme.spacing(1.5),
			'&:first-child': {
				marginTop: 0
			},
			'&:last-child': {
				marginBottom: 0
			}
		}
	},
	container: {},
	dialog: {
		'& .MuiDialogContent-root': {
			padding: 0
		},
		'& .MuiDialogTitle-root': {
			minHeight: theme.spacing(8)
		},
		[theme.breakpoints.down('tablet')]: {
			'& .MuiDialogTitle-root': {
				minHeight: theme.spacing(7)
			}
		}
	},
	dialogContainer: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		flexShrink: 0,
		alignContent: 'flex-start'
	},
	dialogMedia: {
		marginTop: 0,
		marginBottom: 'auto',
		backgroundColor: theme.vars.palette.background.placeholder,
		width: '100%',
		'& > img': {
			objectFit: 'cover'
		}
	},
	dialogContent: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		...theme.mixins.gutters()
	},
	label: {
		paddingLeft: theme.spacing(1.5),
		paddingRight: theme.spacing(1.5),
		borderRadius: `0 0 ${theme.shape.borderRadius}px 0`,
		position: 'absolute',
		textAlign: 'center',
		marginLeft: 0,
		top: 0,
		left: 0,
		...(size === 'small' && {
			fontSize: theme.typography.pxToRem(11)
		})
	}
}), {
	name: 'ProductCard'
});

const usePropertyClasses = createClasses((theme) => ({
	root: {
		marginBottom: theme.spacing(1.5),
		'& > dt': {
			marginBottom: .5
		},
		'& > dd': {
			marginLeft: 0,
			marginBottom: 1
		}
	},
	text: {
		lineHeight: 1.5
	}
}), {
	name: 'ProductCardProperty'
});

export const Property = (props) => {
	const {
		classes: classesProp,
		className,
		children,
		title,
		...rest
	} = props;

	const classes = usePropertyClasses(props);

	return (
		<dl className={classes.root}>
			<dt>
				<Typography
					component="div"
					variant="subtitle2"
					color="inherit"
					noWrap
					{...rest}
					className={classes.text}
				>
					{title}
				</Typography>
			</dt>
			<dd>
				<Typography
					component="div"
					variant="body2"
					color="inherit"
					noWrap
					gutterBottom
					{...rest}
					className={classes.text}
				>
					{children}
				</Typography>
			</dd>
		</dl>
	);
};

export const Price = ({value = ''}) => (
	`${formatNumber(value, 2, {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2
	})} €`
);

export const formatNumberOpts = {
	minimumFractionDigits: 0,
	maximumFractionDigits: 2
};

const CarouselProps = {
	navButtonOffsetX: {
		md: -1
	}
};

const imageSize = {
	small: 'xs',
	medium: 'md',
	large: 'lg'
};

function ProductCard(props) {
	const {
		classes: classesProp,
		className,
		title,
		subtitle,
		imageSrcSet = {},
		size = defaultProps.size,
		children,
		preload,
		dialogContent,
		CardActionsProps,
		CardActionAreaProps,
		shortContent,
		DialogProps,
		labelContent,
		labelColor,
		LabelProps,
		showDialogMedia,
		dialogTitle,
		dialogSubtitle,
		dialogOnly,
		dialogOpen: dialogOpenProp,
		defaultDialogOpen = false,
		onDialogOpen,
		onDialogClose,
		filesData,
		carouselMode
	} = props;

	const { cxCls } = useClsUtils();
	const classes = useClasses(props);

	const isSmall = size === 'small';
	const isMedium = size === 'medium';
	const isLarge = size === 'large';

	const imageSrc = imageSrcSet[imageSize[size]] || null;
	const aspectRatio = sizes.aspectRatio[imageSize[size]];
	const imageWidth = sizes.width[imageSize[size]];
	const imageHeight = imageWidth / aspectRatio;

	const [dialogOpen, setDialogOpenState] = useControlled({
		controlled: dialogOpenProp,
		default: defaultDialogOpen,
		name: 'ProductCard',
		state: false
	});

	const handleDialogOpen = useMemoCallback((...args) => {
		setDialogOpenState(true);
		if (typeof onDialogOpen === 'function') {
			onDialogOpen(...args);
		}
	});

	const handleDialogClose = useMemoCallback((...args) => {
		setDialogOpenState(false);
		if (typeof onDialogClose === 'function') {
			onDialogClose(...args);
		}
	});

	const content = (
		<div className={classes.content}>
			{children}
		</div>
	);

	const label = labelContent ? (
		<Label className={classes.label} color={labelColor || 'info'} {...LabelProps}>
			{labelContent}
		</Label>
	) : null;

	const images = Array.isArray(filesData) ? filesData.filter(item => isImage(item.fileName)).map(item => item.fileSrc) : [];
	const multiImages = carouselMode && images.length > 1;

	return (
		<>
			{!dialogOnly && (
				<>
					{preload && (
						<Helmet>
							<link rel="preload" href={imageSrc} as="image"/>
						</Helmet>
					)}

					<Card
						className={cxCls(
							classes.root,
							isSmall && classes.small,
							isMedium && classes.medium,
							isLarge && classes.large,
							className
						)}
					>
						<CardActionArea
							onClick={handleDialogOpen}
							{...CardActionAreaProps}
							className={classes.cardActionArea}
						>
							<CardMedia
								src={imageSrc}
								width={imageWidth}
								height={imageHeight}
								className={classes.cardMedia}
								ImgClassName={classes.image}
								rounded
							>
								{label}
							</CardMedia>

							<CardHeader className={classes.cardHeader}>
								<CardTitle
									subtitle={subtitle}
									className={classes.cardTitle}
									HeadingProps={HeadingProps}
									SubheadingProps={SubheadingProps}
									classes={{
										heading: classes.heading,
										subheading: classes.subheading
									}}
								>
									{title}
								</CardTitle>

								{isSmall && shortContent}
							</CardHeader>

							{((isLarge && content) || (isMedium && shortContent)) &&
								<CardContent className={classes.cardContent}>
									{isMedium && shortContent}
									{isLarge && content}
								</CardContent>
							}

						</CardActionArea>

						{CardActionsProps && (
							<CardActions {...CardActionsProps} />
						)}
					</Card>
				</>
			)}

			<Dialog
				fullWidth
				maxWidth="sm"
				fullScreen="phone"
				onClose={handleDialogClose}
				title={dialogTitle ?? title}
				subtitle={dialogSubtitle ?? subtitle}
				{...DialogProps}
				open={dialogOpen}
				className={cxCls(
					classes.dialog,
					DialogProps?.className
				)}
				classes={{
					heading: classes.heading,
					subheading: classes.subheading
				}}
				scrollConfig={{
					xs: 'paper',
					md: 'body',
					lg: 'paper'
				}}
			>
				<div className={classes.dialogContainer}>
					{showDialogMedia && imageSrcSet?.lg && (
						multiImages ? (
							<CardMediaCarousel
								src={images}
								width={sizes.width.lg}
								height={sizes.width.lg / sizes.aspectRatio.lg}
								className={classes.dialogMedia}
								CarouselProps={CarouselProps}
							>
								{label}
							</CardMediaCarousel>
						) : (
							<Img
								src={imageSrcSet.lg}
								width={sizes.width.lg}
								height={sizes.width.lg / sizes.aspectRatio.lg}
								className={classes.dialogMedia}
							>
								{label}
							</Img>
						)
					)}
					<div
						className={cxCls(
							classes.content,
							classes.dialogContent
						)}
					>
						{dialogContent || children}
					</div>
				</div>
			</Dialog>
		</>
	);
}

ProductCard.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node,
	shortContent: PropTypes.node,
	dialogContent: PropTypes.node,
	size: PropTypes.oneOf(['small', 'medium', 'large']),
	imageSrcSet: PropTypes.shape({
		xs: PropTypes.string,
		md: PropTypes.string,
		lg: PropTypes.string
	}),
	title: PropTypes.string,
	subtitle: PropTypes.string,
	labelContent: PropTypes.node,
	labelColor: PropTypes.string,
	DialogProps: PropTypes.object,
	LabelProps: PropTypes.object,
	CardActionsProps: PropTypes.object,
	CardActionAreaProps: PropTypes.object,
	dialogOpen: PropTypes.bool,
	defaultDialogOpen: PropTypes.bool,
	preload: PropTypes.bool,
	showDialogMedia: PropTypes.bool,
	dialogOnly: PropTypes.bool,
	onDialogOpen: PropTypes.func,
	onDialogClose: PropTypes.func
};

export default React.memo(ProductCard);
