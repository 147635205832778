import * as React from 'react';
import PropTypes from 'prop-types';
import ScrollableCards from 'components/ScrollableCards';
import SaleCard from 'components/Sale/SaleCard';

const defaultProps = {
	cardSizes: {
		xs: 220,
		sm: 340,
		md: 360
	}
};

function SaleCarousel(props) {
	const {
		cards: cardsData = [],
		CardProps,
		CardComponent = SaleCard,
		cardSizes = defaultProps.cardSizes,
		...rest
	} = props;

	const cards = (
		cardsData.map((cardData, key) => (
			<CardComponent
				{...CardProps}
				{...cardData}
				preload={key === 0}
				key={key}
			/>
		))
	);

	return (
		<ScrollableCards {...rest} cardSizes={cardSizes}>
			{cards}
		</ScrollableCards>
	);
}

SaleCarousel.propTypes = {
	cards: PropTypes.array.isRequired,
	CardProps: PropTypes.object
};

export default React.memo(SaleCarousel);
