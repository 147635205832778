/* global WEBPACK_DEV */

import * as React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import Avatar from 'react-app/components/Avatar';
import FinalForm from 'react-app/components/FinalForm';
import { useMemoCallback } from 'react-app/hooks';
import { formatNumber, formatDigits } from 'lib/shared-helpers.cjs';
import { useDeferredUser } from 'context/user';
import {
	FormTextField,
	FormGridItem,
	FormOfAddressFromField,
	EmailFormField,
	MessageFormField,
	FormSubmitButton,
	FormResetButton,
	PrivacyPolicyCheckbox,
	RequiredHint
} from 'react-app/components/FinalForm/components';

const initialValues = WEBPACK_DEV ? {
	email: 'm.teichmann@holzweidauer.com',
	privacyPolicy: true,
	formOfAddress: 'mr',
	firstname: 'John',
	lastname: 'Doe',
	phone: '03 71 / 8 42 29 0',
	company: 'Weidauer Holzhandel GmbH',
	street: 'Oberfrohnaer Straße 59',
	zip: '09117',
	city: 'Chemnitz',
	comment: 'Kommentar'
} : {};

const validateQuantity = (value => !isNaN(+value) && +value > 0 ? undefined : true);

function ShippingForm(props) {
	const [ {userType, isWholesale} ] = useDeferredUser();

	const TAX_VALUE = isWholesale ? 1.19 : 1;

	const {
		classes: classesProp,
		className,
		selectedItems: selectedItemsProp = [],
		submitButtonLabel = 'Absenden',
		companyRequired = isWholesale,
		phoneRequired = false,
		companyOnly = false,
		onDelete,
		extraValues,
		...rest
	} = props;

	const selectedItems = React.useMemo(() => selectedItemsProp, [selectedItemsProp]);
	const selectedItemIds = React.useMemo(() => selectedItemsProp.map(item => item?.id).filter(Boolean), [selectedItemsProp]);

	for (const itemId of selectedItemIds) {
		initialValues[`quantity_${itemId}`] = 1;
	}

	const handleDeleteClick = useMemoCallback((item) => (...args) => {
		if (typeof onDelete === 'function') {
			onDelete(item, ...args);
		}
	});

	function renderForm(formProps) {
		const {
			submitting,
			resetForm,
			disabled: disabledProp,
			values
		} = formProps;

		const FormTextFieldProps = {
			submitting
		};

		const disabled = selectedItems.length === 0 ? true : disabledProp;

		let totalAmount = 0;

		return (
			<>
				{selectedItems.map((item = {}, key) => {
					const {
						id,
						title,
						subtitle,
						label,
						itemNo,
						imageSrc,
						retailPrice,
						unit
					} = item || {};

					if (!id) {
						return null;
					}

					const quantityName = `quantity_${id}`;
					totalAmount += +values[quantityName] * formatDigits(+retailPrice / TAX_VALUE);


					return (
						<FormGridItem
							key={key}
							container
							size={12}
							alignItems="center"
							gap={2}
						>
							{imageSrc && (
								<FormGridItem size={{xs: 3, sm: 'auto'}} sx={{maxWidth: 64}}>
									<Avatar
										src={imageSrc}
										width={48}
										height={48}
									/>
								</FormGridItem>
							)}
							<FormGridItem size={{xs: 9, sm: 'grow'}}>
								<Typography
									component="p"
									variant="subtitle2"
									paddingRight={2}
								>
									{label ?? title}
								</Typography>
								{subtitle && (
									<Typography
										component="p"
										variant="caption"
									>
										{subtitle} {`${itemNo ? ` — ${itemNo}` : ''}`}
									</Typography>
								)}
							</FormGridItem>
							<FormGridItem size={{xs: 6, sm: 2}}>
								<FormTextField
									required
									size="small"
									label="Menge"
									name={quantityName}
									type="number"
									InputProps={{...(unit && unit !== 'Stück' && {
										endAdornment: (
											<InputAdornment position="end">
												{unit}
											</InputAdornment>
										)
									})}}
									helperTextError="Menge ungültig"
									inputProps={{
										min: 1
									}}
									validate={validateQuantity}
									InputLabelProps={{
										shrink: true
									}}
									style={{margin: 0}}
									{...FormTextFieldProps}
								/>
							</FormGridItem>
							{retailPrice && (
								<FormGridItem size={{xs: 6, sm: 'auto'}}>
									<Typography variant="subtitle2">
										{`${formatNumber((retailPrice / TAX_VALUE))} €`}
									</Typography>
									<Typography variant="caption">
										{unit && `Preis/${unit}`}
									</Typography>
								</FormGridItem>
							)}
							{selectedItems.length > 1 && (
								<FormGridItem
									size={{xs: 3, sm: 1}}
									sx={{display: 'flex', justifyContent: 'flex-end', maxWidth: 48}}
								>
									<IconButton
										size="small"
										onClick={handleDeleteClick(item)}
										title="Produkt entfernen"
									>
										<DeleteForeverIcon fontSize="small"/>
									</IconButton>
								</FormGridItem>
							)}
							<Divider sx={{width: '100%', marginBottom: 2}}/>
						</FormGridItem>
					);
				})}

				{totalAmount ? (
					<FormGridItem size={12}>
						<Typography
							align="right"
							component="div"
							variant="caption"
							sx={{fontWeight: 'bold', marginTop: -1, marginBottom: 2}}
						>
							Abholpreis gesamt {isWholesale ? 'exkl.' : 'inkl. 19%'} MwSt.: {' '}
							<Typography component="span" variant="subtitle1" color="primary" style={{fontWeight: 'bold'}}>
								{`${formatNumber(totalAmount)} €`}
							</Typography>
						</Typography>
					</FormGridItem>
				) : null}

				{companyOnly !== true ? (
					<>
						<FormGridItem size={{xs: 6, md: 3}}>
							<FormOfAddressFromField required {...FormTextFieldProps}/>
						</FormGridItem>

						<FormGridItem size={{xs: 6, md: 4.5}}>
							<FormTextField required name="firstname" {...FormTextFieldProps}/>
						</FormGridItem>

						<FormGridItem size={{xs: 12, md: 4.5}}>
							<FormTextField required name="lastname" {...FormTextFieldProps}/>
						</FormGridItem>
					</>
				) : null}

				<FormGridItem>
					<FormTextField required={companyOnly || companyRequired} name="company" {...FormTextFieldProps}/>
				</FormGridItem>

				<FormGridItem size={{xs: 12, md: 6}}>
					<EmailFormField required {...FormTextFieldProps}/>
				</FormGridItem>

				<FormGridItem size={{xs: 12, md: 6}}>
					<FormTextField required={phoneRequired} name="phone" {...FormTextFieldProps}/>
				</FormGridItem>

				<FormGridItem>
					<FormTextField required name="street" {...FormTextFieldProps}/>
				</FormGridItem>

				<FormGridItem size={{xs: 12, md: 5}}>
					<FormTextField required name="zip" {...FormTextFieldProps}/>
				</FormGridItem>

				<FormGridItem size={{xs: 12, md: 7}}>
					<FormTextField required name="city" {...FormTextFieldProps}/>
				</FormGridItem>

				<FormGridItem>
					<MessageFormField name="comment" {...FormTextFieldProps}/>
				</FormGridItem>

				<FormGridItem>
					<PrivacyPolicyCheckbox/>
				</FormGridItem>

				<FormGridItem size={{xs: 6, md: 4}} offset={{md: 2}}>
					<FormSubmitButton
						size="small"
						disabled={disabled}
						label={submitButtonLabel}
					/>
				</FormGridItem>

				<FormGridItem size={{xs: 6, md: 4}}>
					<FormResetButton
						size="small"
						disabled={disabled}
						onClick={resetForm}
					/>
				</FormGridItem>

				<FormGridItem>
					<RequiredHint/>
				</FormGridItem>
			</>
		);
	}

	return (
		<FinalForm
			{...rest}
			renderForm={renderForm}
			initialValues={initialValues}
			fetchUrl="/api/forms/product-shipping"
			extraValues={{
				...extraValues,
				selectedItemIds,
				userType
			}}
		/>
	);
}

ShippingForm.propTypes = {
	selectedItems: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
		subtitle: PropTypes.string,
		retailPrice: PropTypes.number,
		unit: PropTypes.string,
		itemNo: PropTypes.string,
		imageSrc: PropTypes.string
	}))
};

export default React.memo(ShippingForm);
