import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

function Disclaimer(props) {
	const {children, ...rest} = props;

	return (
		<Typography
			gutterBottom
			component="p"
			variant="caption"
			align="center"
			marginX={2}
			lineHeight={1.4}
			{...rest}
		>
			{children}
		</Typography>
	);
}

Disclaimer.propTypes = {
	children: PropTypes.node
};

export default React.memo(Disclaimer);
