import Disclaimer from 'components/Disclaimer';
import { useDeferredUser } from 'context/user';
import { disclaimer } from 'config/sale.cjs';

export default function SaleDisclaimer(props) {
	const [ {userType = 'retail'} ] = useDeferredUser();
	return (
		<Disclaimer {...props}>
			{disclaimer[userType]}
		</Disclaimer>
	);
}
